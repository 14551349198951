<div  *ngIf="this.selectedLabIds.length >0">
<div class="container" *ngIf="this.selectedLab?.['account_name'] != ''" style="background: #D7E0F7;">
  <div [style]="{ width: '5%' }"></div>
  <div [style]="{ width: '90%' }">
    <div slot="header" class="header-section">
      <div class="row">
       
        <h4 style="display: inline-block;" *ngIf="selectedLab.length >1; else singleLab" id="overview-multi-lab-name-label" >Multiple labs overview: &nbsp; </h4> 
        <p style="display: inline-block; cursor: default;" id="overview-multi-lab-names" *ngIf="selectedLab.length >1" title="{{this.selectedCentricLabs}}">  {{this.selectedCentricLabs | charslice : 125 }}</p>
        <ng-template #singleLab>
          <h4 id="overview-single-lab-name" >{{ this.selectedLab.account_name }}</h4>
        </ng-template>
      </div>
    </div>
  </div>
  <div [style]="{ width: '5%' }"></div>
</div>

<div class="container" *ngIf="this.selectedLab?.['account_name'] != ''">
  <div [style]="{ width: '5%' }"></div>
  <div [style]="{ width: '90%' }">
    <div slot="header" class="header-section">
      <div class="row">
        <h3 i18n="@@overview" id="overview-txt">Overview</h3>
      </div>
    </div>
  </div>
  <div [style]="{ width: '5%' }"></div>
</div>

<div class="container" *ngIf="this.selectedLabIds.length >0">
  <div [style]="{ width: '5%' }"></div>
  <div [style]="{ display: 'flex', 'flex-wrap': 'wrap', width: '90%', 'justify-content': 'space-between' }">
    <ng-container *ngFor="let d of data.features">
      <owc-card id="overview-feature-card" flat scroll-shadow class="lower-card" [ngClass]="{ comingsoon: d.extra === 'Coming soon', activate: d.extra == 'Activate license' }">
        <div slot="header" class="header-section">
          <div class="row">
            <div id="overview-feature-icon" class="zero">
              <owc-icon #iconElement name="security" type="legacy" class="mat-header-icon" [style]="{ color: d.status }"> </owc-icon>
            </div>
            <div class="two" id="overview-feature-name" [ngClass]="{ comingsoon: d.extra === 'Coming soon' }">
              <h3 id="{{ 'overview-feature-' + d.name }}" title="{{ d.name }}" style="margin: 0px 3px; word-break: break-all;position: absolute;">{{ d.name }}</h3>
            </div>
            <div id="overview-feature-desc" class="three " [style]="{ display: 'grid' }">
              {{ d.desc }}
            </div>
          </div>
        </div>
        <div style="margin:auto 0; overflow-x: hidden;">
          <!--  Firewall Pie Chart-->
          <dl-doughnut-chart *ngIf="d.extra === 'View status' && this.totalFirewallDevices > 0" [firewallPieData]="firewallPieData"></dl-doughnut-chart>
          <div style="text-align: center;" *ngIf="d.extra === 'View status' && this.totalFirewallDevices <= 0 && !this.isLoading">
            <div><owc-icon name="notification_warning" family="outlined"></owc-icon></div>
            <div><span i18n="@@firewall-tile-empty-msg">No firewall device(s) found</span></div>
          </div>
          <!--  devices Pie Chart -->
          <dl-doughnut-chart *ngIf="d.extra === 'View devices' && this.devicesPieData.length > 0 && this.devicesChartFlag" [devicesChart]="devicesChartFlag" [devicesPieData]="devicesPieData"></dl-doughnut-chart>
          <div style="text-align: center;" *ngIf="d.extra === 'View devices' && this.devicesPieData.length <= 0 && !this.isLoading">
            <div><owc-icon name="notification_warning" family="outlined"></owc-icon></div>
            <div><span i18n="@@device-tile-empty-msg">No device(s) found</span></div>
          </div>
          <!--  Advisory bar Chart -->
          <dl-bar-chart *ngIf="d.extra === 'View advisories' && vulnerabilityChartFlag" [vulnerabilityChartData]="vulnerabilityChartData"></dl-bar-chart>
          <div style="text-align: center;" *ngIf="d.extra === 'View advisories' && !vulnerabilityChartFlag && !this.isLoading">
            <div><owc-icon name="notification_warning" family="outlined"></owc-icon></div>
            <div><span i18n="@@vulnerability-tile-empty-msg">No vulnerabilities reported in last (7)Days</span></div>
          </div>

          <img id="overview-activate-license-image" width="100%" *ngIf="d.extra === 'Activate license'" alt="" [src]="d.imagePath" />
        
          <div *ngIf="d.extra === 'View packages' && sdsProducts.length > 0" class="mtb-6 col-12">
            <div class="col-1"></div>
            <div class="col-10">
                <owc-editable-dropdown
                #editableDropdownElement
                [(ngModel)]="selectedProduct"
                [label]="'Select product'"
                [limit]="10" 
                class="product-dropdown"
                [placeholder]="'Select product'"
                (selectChange)="onProductChange($event)"
              >
                <ng-container *ngFor="let product of sdsProducts">
                  <owc-list-item value="{{ product }}">{{
                    product.name
                  }}</owc-list-item>
                </ng-container>
              </owc-editable-dropdown>
              </div>
              <div class="col-1"></div>
          </div>
        
        </div>
        <div slot="footer">
          <owc-button *ngIf="d.extra === 'View status'" variant="secondary" style="float: right" (click)="viewStatus()" i18n="@@view-status-btn"> {{ d.extra }} </owc-button>
          <owc-button *ngIf="d.extra === 'View devices'" variant="secondary" style="float: right" (click)="viewDevices()" i18n="@@view-devices-btn"> {{ d.extra }} </owc-button>
          <owc-button *ngIf="d.extra === 'View advisories'" variant="secondary" style="float: right; z-index: 0;" (click)="viewAdvisories()" i18n="@@view-advisories"> {{ d.extra }} </owc-button>
          <owc-button id="overview-activate-license-{{ d.name }}" *ngIf="d.extra === 'Activate license'" variant="secondary" style="float: right" (click)="activateLicense(d)" i18n="@@activate-license"> {{ d.extra }} </owc-button>
          <owc-button id="overview-activate-license-{{ d.name }}" *ngIf="d.extra === 'View packages'"   [disabled]="!this.selectedProductUrl" variant="secondary" style="float: right; z-index: 0;" (click)="viewPackages(d)" i18n="@@view-packages"> {{ d.extra }} </owc-button>
          <div class="four " id="overview-feature-tag" *ngIf="d.extra && d.extra === 'Coming soon'">
            <owc-badge #badgeElement [style]="{ background: 'transparent', border: '1px solid grey', color: 'black', 'font-weight': '500' }">
              <owc-icon id="badge-icon" name="time_elapsed" type="legacy" class="mat-header-icon icon-variant-default icon-type-legacy" variant="default" hydrated="" [style]="{ 'font-size': '16px' }"> </owc-icon>
              <span id="{{ 'overview-feature-tag-' + d.extra }}" i18n="@@coming-soon">{{ d.extra }}</span>
            </owc-badge>
          </div>

          <div class="four " id="overview-feature-badge" *ngIf="d.extra && d.extra === 'View advisories'">
            <owc-badge #badgeElement class="severity-badge-element">
              <span title="Last 7 days vulnerabilities count">{{ latestVulnCount }}</span>
            </owc-badge>
          </div>
        </div>
      </owc-card>
    </ng-container>
  </div>
  <div [style]="{ width: '5%' }"></div>
</div>
</div>

<div class="container" style="margin-top: 4%; margin-left: 40%;" *ngIf="this.selectedLabIds.length === 0">
  <p id="no-lab-selected"> Please select at least one laboratory </p>
</div>
