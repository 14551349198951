<owc-snackbar hidden></owc-snackbar>

<ng-container *ngIf="isLoggedIn">
  <div id="top-container" class="top-container">
    <owc-select-dropdown id="selectDropdownElement" [style.width.px]="250" [multiple]="isMultiple"  [selectAllText]="'Select account(s)'"
       #selectDropdownElement size="compact"  class="account-selector" placeholder="Select account(s)" (panelOpen)="panelOpenHandler($event)"
       label="label for select-dropdown"  (selectChange)="selectChangeHandler($event)"
      >
      <ng-container id="licensed-labs" *ngFor="let item of accounts?.['licensed'];index as j">
        <owc-list
          id="licensed-labs-title"
          *ngIf="j == 0"
          style="align-items: center;display: flex;background-color: var(--one-color-interaction-hover-neutral-1);
        height: 2rem"
          size="default"
          i18n="@@licensed-labs"
        >
          Licensed labs
        </owc-list>
        <!-- <owc-list-item id="licensed-lab-item" [icon]="item.icon"  *ngIf=" objectKeys(item.license).indexOf('SoC') === -1" [selected]="this.selectedLabIndex == j">
           <span > {{ item.account_name }} </span>
          <p style="font-size:10px;" id="licensed-lab-serial-no">{{ item.crmNumber }}</p>
        </owc-list-item> -->

        <!--Following items will shown only in multiple case and if SoC license-->
        <owc-list-item id="licensed-lab-item-{{item.crmNumber}}" [icon]="item.icon" [selected]="item.is_lab_selected == true">
          <owc-checkbox #checkboxElement id="licensed-lab-chkbox-{{item.crmNumber}}" [checked]="item.is_lab_selected" style="display: inline-block;position: absolute;"> </owc-checkbox> 
           <span style="margin-left: 24px;"  id="licensed-lab-{{item.crmNumber}}"> {{ item.account_name }} </span>
          <p style="font-size:10px;" id="p-{{item.crmNumber}}">{{ item.crmNumber }}</p>
        </owc-list-item>
       
      </ng-container>
      <ng-container id="non-licensed-labs" *ngFor="let item of accounts?.['non-licensed'];index as i">
      <ng-container>
        <owc-list
          id="non-licensed-labs-title"
          *ngIf="i == 0"
          style="align-items: center;display: flex;background-color: var(--one-color-interaction-hover-neutral-1);
      height: 2rem"
          header="Non licensed labs"
          tabindex="0"
          size="default"
          nested=""
          hydrated=""
          i18n="@@non-licensed-labs"
        >
          Non licensed labs
        </owc-list>

        <owc-list-item id="non-licensed-lab-item-{{item.crmNumber}}" style="background-color: lightgrey;opacity: 0.3;" [icon]="item.icon" [selected]="(i===0 && accounts?.['licensed'].length === 0)">
          {{ item.account_name }}
          <p  style="font-size:10px;"  id="non-licensed-lab-{{item.crmNumber}}">{{ item.crmNumber }}</p>
        </owc-list-item>
      </ng-container>
      </ng-container>
      
    </owc-select-dropdown>
   
  </div>
  <div id="main-page" class="container" style="background: #fff;">
    <owc-tabs #tabsElement id="tabsElement" [(ngModel)]="tabValue" (tabChange)="onTabChange($event.detail)">
      <owc-tab value="overview" class="tab" id="overview-tab"><span i18n="@@overview" id="overview-txt">Overview</span></owc-tab>
      <owc-tab value="devices" [ngClass]="{ 'disable-tab': licenseExpired === 1 || (this.selectedLab.length === 0 && this.selectedLabsIndex.length === 0) }" class="tab" id="devices-tab"><span i18n="@@devices" id="devices-txt">Devices</span></owc-tab>
      <owc-tab value="view-advisories"  [ngClass]="{ 'disable-tab': licenseExpired === 1 || (this.selectedLab.length === 0 && this.selectedLabsIndex.length === 0 )}" class="tab" id="view-advisories-tab"><span i18n="@@view-advisories" id="advisories-txt">View advisories</span></owc-tab>
    </owc-tabs>
  </div>
</ng-container>
<router-outlet></router-outlet>
<div class="space-4"></div>
<p [style]="{ 'text-align': 'center' }" id="version">v{{ currentApplicationVersion }}</p>
